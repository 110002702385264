import * as React from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import Content from '../components/Content'
import Layout from '../layouts/layout'
import { getPhoneByFormat } from '../styles/mixins'

type Partner = {
  title: string
  contact: {
    name: string
    phone: number
  }
  address: string
  services: string[]
  workHours: string[]
  comment?: string
}

const partners: Partner[] = [
  {
    title: 'Сход-развал',
    contact: {
      name: 'Александр',
      phone: 9039752105
    },
    address: '141290, г. Красноармейск, ул. Лермонтова, д. 2',
    services: ['Сход-развал легковых автомобилей'],
    workHours: ['10:00 - 18:00', 'чт, вс - выходной'],
    comment: 'Желательно по предварительной записи'
  },
  {
    title: 'Шиномонтаж',
    contact: {
      name: 'Максим',
      phone: 9680468980
    },
    address: '141290, г. Красноармейск, ул. Лермонтова, д. 2',
    services: ['Шиномонтаж'],
    workHours: ['ежедневно 10:00 - 18:00'],
    comment: 'Скидка 10% покупателям магазина автозапчастей. Подтверждение по чеку.'
  },
  {
    title: 'Автосервис',
    contact: {
      name: 'Алексей',
      phone: 9690546713
    },
    address: '141290, г. Красноармейск, ул. Академика Янгеля, 23/19',
    services: ['Слесарные работы', 'Сварочные работы'],
    workHours: ['ежедневно 9:00 - 20:00'],
    comment: '3 (третий) бокс слева'
  },
  {
    title: 'Автосервис',
    contact: {
      name: 'Георгий',
      phone: 9160036061
    },
    address: '141214, поселок Зверосовхоз, ул. Школьная, д. 3',
    services: ['Кузовной ремонт', 'Лако-красочные работы', 'Слесарные работы', 'Сварочные работы'],
    workHours: ['10:00 - 18:00'],
    comment: 'По предварительному звонку'
  },
  {
    title: 'Автосервис КР-АВТО',
    contact: {
      name: 'Иван',
      phone: 9957771150
    },
    address: '141290, г. Красноармейск, ул. Лермонтова, д. 2',
    services: ['Слесарные работы', 'Электрика', 'Заправка кондиционеров'],
    workHours: ['ежедневно 9:00 - 20:00']
  },
  {
    title: 'Автосервис',
    contact: {
      name: 'Максим',
      phone: 9680468980
    },
    address: '141290, г. Красноармейск, ул. Лермонтова, д. 2',
    services: ['Слесарные работы', 'Установка стекол'],
    workHours: ['ежедневно 10:00 - 18:00']
  }
]

const Partner = () => (
  <Layout>
    <Content>
      <Container>
        <h1>Партнеры</h1>
        <Row>
          {partners.map(p => (
            <Col xs={12} sm={12} md={5} lg={5}>
              <h3>
                <span style={{ borderBottom: '3px solid #c91212' }}>{p.title}</span>
              </h3>
              <p>
                {p.contact.name},{' '}
                <a href={`tel:${getPhoneByFormat(p.contact.phone.toString(), '+7 (xxx) xxx-xx-xx')}`}>
                  {getPhoneByFormat(p.contact.phone.toString(), '+7 (xxx) xxx-xx-xx')}
                </a>
              </p>
              <p>{p.address}</p>
              <strong>График работы:</strong>
              {p.workHours.map(wh => (
                <div>{wh}</div>
              ))}
              <div style={{ marginTop: '10px' }}>
                <strong>Услуги</strong>
              </div>
              <ul>
                {p.services.map(s => (
                  <li>{s}</li>
                ))}
              </ul>
              <p>{p.comment}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </Content>
  </Layout>
)

export default Partner
